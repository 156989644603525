import React from "react";
import {
  ExpectationsContent,
  ExpectationsContentWrapper,
} from "./Expectations.styles";

function Expectations() {
  return (
    <ExpectationsContentWrapper disableBackground={true}>
      <h2>What to Expect:</h2>
      <ExpectationsContent>
        <p>
          When you reach out, you're not just sending a message into the void. I
          personally read and respond to every inquiry within 24-48 hours. Let's
          start a real conversation and see where it leads us.
        </p>
      </ExpectationsContent>
    </ExpectationsContentWrapper>
  );
}

export default Expectations;
