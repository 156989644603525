// services/ExperienceService.js
import axios from "axios";

const BASE_URL = "/api/experience/";
let cache = null; // Initialize cache

// Function to perform the actual fetch with retry logic
const fetchWithRetry = async (url, retries = 1, backoff = 300) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    if (retries === 0) throw error; // Throw error after max retries
    console.error(`Retry in ${backoff}ms`, error.message);
    await new Promise((resolve) => setTimeout(resolve, backoff)); // Wait for backoff duration
    return fetchWithRetry(url, retries - 1, backoff * 2); // Recursive call with decremented retries and increased backoff
  }
};

export const fetchExperiences = async () => {
  if (cache) return cache; // Return cached data if it exists

  try {
    const response = await fetchWithRetry(BASE_URL); // Use the fetchWithRetry function
    cache = response.data; // Cache the fetched data
    return cache; // Return the cached data
  } catch (error) {
    console.error("Error fetching experiences:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};
