import React from "react";
import {
  MyServicesContent,
  MyServicesContentWrapper,
} from "./MyServices.styles";

function MyServices() {
  return (
    <MyServicesContentWrapper disableBackground={true}>
      <h2>How I Can Help</h2>
      <MyServicesContent>
        <ul>
          <li>Fractional CMO/CTO</li>
          <li>Marketing Strategy & Execution</li>
          <li>Creative Development</li>
          <li>Team Asset &amp; Collaborator</li>
        </ul>
      </MyServicesContent>
    </MyServicesContentWrapper>
  );
}

export default MyServices;
