import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { hexToRgba } from "../../../utils/hexToRGBA";

export const ExpectationsContentWrapper = styled(BentoItem)`
  grid-column: 5 / span 4;
  grid-row: 6 / span 2;
  height: fit-content;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 6 / -1;
    grid-row: 6;
    padding-top: 1rem;
  `}
  ${media.between("laptopS", "laptopL")`
    grid-column: 6 / -1;
    grid-row: 5;
    padding-top: 1rem;
  `}

  ${media.between("tabletL", "laptopS")`
    grid-column: 1 / -1;
    grid-row:7;
  `}

  ${media.between("tabletS", "tabletL")`
    grid-column: 1 / -1;
    grid-row: 7;
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: auto / span 2;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: auto / span 2;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

export const ExpectationsContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 0.85)};

  &:hover {
    background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 0.75)};
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1rem 2rem 0rem 1.5rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;
