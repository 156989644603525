/**
 * ErrorBoundary.js
 *
 * Catches JavaScript errors anywhere in its child component tree,
 * logs those errors, and displays a fallback UI instead of the component tree that crashed.
 *
 * @extends React.Component
 */

import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state to track error status
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  /**
   * Update state so the next render will show the fallback UI.
   *
   * @param {Error} error - The error that was thrown
   * @returns {Object} Updated state object
   */
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  /**
   * Catch errors in any components below and re-render with error message
   *
   * @param {Error} error - The error that was thrown
   * @param {React.ErrorInfo} errorInfo - Component stack trace information
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    // Check if an error occurred
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    // If no error, render children normally
    return this.props.children;
  }
}

export default ErrorBoundary;

// Usage example:
//
// import ErrorBoundary from './ErrorBoundary';
//
// function App() {
//   return (
//     <ErrorBoundary>
//       <MyComponent />
//     </ErrorBoundary>
//   );
// }
//
// This will catch errors in MyComponent and its children,
// preventing the entire app from crashing and showing a fallback UI instead.

// General guidelines:
// - Wrap top-level route components with ErrorBoundary to catch errors in entire subtrees of your UI.
// - Use multiple ErrorBoundary components to wrap different parts of your application for more granular error handling.
// - Customize the fallback UI as needed for your application's design.
// - Consider sending error reports to a logging service in componentDidCatch for better error tracking.
