import React from "react";
import {
  PhilosophyContent,
  PhilosophyContentWrapper,
} from "./Philosophy.styles";

function Philosophy() {
  return (
    <PhilosophyContentWrapper disableBackground={true}>
      <h2>Philosophy</h2>
      <PhilosophyContent>
        <p>
          The most powerful tool in any entrepreneurial toolkit isn't some fancy
          algorithm or a viral campaign. It's the ability to listen —{" "}
          <em>really listen</em> — to what people need, and then find creative
          ways to <em>co-create</em> solutions with them.
        </p>
      </PhilosophyContent>
    </PhilosophyContentWrapper>
  );
}

export default Philosophy;
