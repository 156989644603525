// components/SlideOut/anims.js

import { breakpoints } from "../../styles/breakpoints";

export const createSlideOutAnimations = (isCollapsed, theme, viewport) => {
  const { width, isLandscape } = viewport;

  const getAnimationForBreakpoint = (breakpoint) => [
    {
      animate: (timeline) => {
        let gridTemplateRows,
          gridTemplateColumns,
          slideOutWidth,
          borderRight,
          collapseButtonOpacity,
          collapseButtonRight,
          collapseButtonBottom,
          collapseButtonHeight,
          collapseButtonWidth,
          collapseButtonBorderRadius;

        switch (breakpoint) {
          case "mobileS":
          case "mobileM":
          case "mobileL":
            gridTemplateRows = isLandscape ? "2rem 1fr 2rem" : "3rem 1fr";
            gridTemplateColumns = isCollapsed ? "3rem 0.1rem" : "3rem 1fr";
            slideOutWidth = isCollapsed ? "3rem" : "100%";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-1.525rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "0.5rem" : "0.5rem";
            collapseButtonHeight = "35px";
            collapseButtonWidth = "25px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
            break;

          case "tabletS":
            gridTemplateRows = isLandscape ? "2.75rem 1fr" : "3.5rem 1fr";
            gridTemplateColumns = isCollapsed
              ? isLandscape
                ? "3.5rem 0.1rem"
                : "3.5rem 0.1rem"
              : "3.5rem 1fr";
            slideOutWidth = isCollapsed
              ? isLandscape
                ? "3.5rem"
                : "3.5rem"
              : "100%";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-1.25rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "0.5rem" : "0.5rem";
            collapseButtonHeight = "30px";
            collapseButtonWidth = "20px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
            break;

          case "tabletM":
            gridTemplateRows = isLandscape ? "2.75rem 1fr" : "3.5rem 1fr";
            gridTemplateColumns = isLandscape
              ? isCollapsed
                ? "3.5rem 0.1rem"
                : "3.5rem 1fr"
              : isCollapsed
                ? "3.5rem 1fr"
                : "3.5rem 1fr";
            slideOutWidth = isLandscape
              ? isCollapsed
                ? "3.5rem"
                : "17.5rem"
              : isCollapsed
                ? "3.5rem"
                : "100%";

            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-1.25rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "0.5rem" : "0.5rem";
            collapseButtonHeight = "30px";
            collapseButtonWidth = "20px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
            break;

          case "tabletL":
            gridTemplateRows = isCollapsed
              ? isLandscape
                ? "2.75rem"
                : "3.5rem"
              : isLandscape
                ? "2.75rem"
                : "3.5rem 1fr";
            gridTemplateColumns = isCollapsed
              ? isLandscape
                ? "3.5rem 0.1rem"
                : "3.5rem 0.1rem"
              : isLandscape
                ? "3.5rem 1fr"
                : "3.5rem 1fr";
            slideOutWidth = isCollapsed
              ? isLandscape
                ? "3.5rem"
                : "3.5rem"
              : isLandscape
                ? "20rem"
                : "100%";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-1.25rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "0.5rem" : "0.5rem";
            collapseButtonHeight = "30px";
            collapseButtonWidth = "20px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
            break;

          case "laptopS":
            gridTemplateRows = "5.25rem 1fr";
            gridTemplateColumns = isCollapsed
              ? "5.25rem 0.1rem"
              : "5.25rem 1fr";
            slideOutWidth = isCollapsed ? "5.25rem" : "25rem";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = "-37.5px";
            break;
          case "laptopM":
            gridTemplateRows = "5.25rem 1fr";
            gridTemplateColumns = isCollapsed
              ? "5.25rem 0.1rem"
              : "5.25rem 1fr";
            slideOutWidth = isCollapsed ? "5.25rem" : "25rem";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = "-37.5px";
            break;
          case "laptopL":
            gridTemplateRows = "5.25rem 1fr";
            gridTemplateColumns = isCollapsed
              ? "5.25rem 0.1rem"
              : "5.25rem 1fr";
            slideOutWidth = isCollapsed ? "5.25rem" : "30rem";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = "-37.5px";
            break;

          case "desktop":
            gridTemplateRows = "5.25rem 1fr";
            gridTemplateColumns = isCollapsed
              ? "5.25rem 0.1rem"
              : "5.25rem 1fr";
            slideOutWidth = isCollapsed ? "5.25rem" : "30rem";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-2.125rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "1.25rem" : "1.25rem";
            collapseButtonHeight = "40px";
            collapseButtonWidth = "30px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
            break;

          case "desktopL":
            gridTemplateRows = "5.25rem 1fr";
            gridTemplateColumns = isCollapsed
              ? "5.25rem 0.1rem"
              : "5.25rem 1fr";
            slideOutWidth = isCollapsed ? "5.25rem" : "30rem";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-2.125rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "1.25rem" : "1.25rem";
            collapseButtonHeight = "40px";
            collapseButtonWidth = "30px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
            break;
          default:
            gridTemplateRows = "5.25rem 1fr";
            gridTemplateColumns = isCollapsed
              ? "5.25rem 0.1rem"
              : "5.25rem 1fr";
            slideOutWidth = isCollapsed ? "5.25rem" : "30rem";
            borderRight = `2px solid ${theme.primaryColor}`;
            collapseButtonOpacity = isCollapsed ? 1 : 0;
            collapseButtonRight = isCollapsed ? "-2.125rem" : "0.5rem";
            collapseButtonBottom = isCollapsed ? "1.25rem" : "1.25rem";
            collapseButtonHeight = "40px";
            collapseButtonWidth = "30px";
            collapseButtonBorderRadius = isCollapsed ? "0 5px 5px 0" : "5px";
        }

        timeline.to(".slide-out", {
          width: slideOutWidth,
          gridTemplateColumns: gridTemplateColumns,
          gridTemplateRows: gridTemplateRows,
          borderRight: borderRight,
          duration: 0.3,
          ease: "power2.inOut",
        });

        timeline.to(
          ".details-container",
          {
            opacity: isCollapsed ? 0 : 1,
            duration: 0.2,
            ease: "power2.inOut",
          },
          "<"
        );

        timeline.to(
          ".collapse-button",
          {
            right: collapseButtonRight,
            bottom: collapseButtonBottom,
            height: collapseButtonHeight,
            width: collapseButtonWidth,
            borderRadius: collapseButtonBorderRadius,
            opacity: collapseButtonOpacity,
            duration: 0.3,
            ease: "power2.inOut",
          },
          "<"
        );
      },
    },
  ];

  // Return animations for all breakpoints
  return Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = getAnimationForBreakpoint(breakpoint);
    return acc;
  }, {});
};
