// services/FactService.js
import axios from "axios";

const BASE_URL = "/api/facts/"; // Adjusted to point to the facts endpoint

export const fetchFacts = async () => {
  try {
    const { data } = await axios.get(BASE_URL);
    return data;
  } catch (error) {
    console.error("Error fetching facts:", error);
    throw error;
  }
};
