import React from "react";
import {
  CareerSummaryContent,
  CareerSummaryContentWrapper,
} from "./CareerSummary.styles";

function CareerSummary() {
  return (
    <CareerSummaryContentWrapper disableBackground={true}>
      <h2>What I Do</h2>
      <CareerSummaryContent>
        <p>
          I help creatives, small businesses, and scrappy entrepreneurs find
          their tribes, build their MVPs, and carve out their place in the
          world.
        </p>
      </CareerSummaryContent>
    </CareerSummaryContentWrapper>
  );
}

export default CareerSummary;
