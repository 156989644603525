// ContactForm.js
import React, { useState } from "react";
import { useViewport } from "../../context/ViewportContext";
import useStore from "../../stores/store";
import Button from "../Button/Button";
import {
  FormContainer,
  Form,
  StyledForm,
  FormField,
  Input,
  Textarea,
  MessageDiv,
  StyledButton,
} from "./ContactForm.styles";

function ContactForm({ updateHeading }) {
  const { theme } = useStore();
  const viewport = useViewport();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState({
    isSubmitting: false,
    isSuccess: false,
    isError: false,
    message: "",
  });

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
    );
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.message) {
      setSubmissionStatus({
        ...submissionStatus,
        message: "Please fill in all fields.",
      });
      return false;
    }
    if (!validateEmail(formData.email)) {
      setSubmissionStatus({
        ...submissionStatus,
        message: "Please enter a valid email address.",
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setSubmissionStatus({ ...submissionStatus, message: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSubmissionStatus({ ...submissionStatus, isSubmitting: true });
    updateHeading("Submitting...");

    try {
      const response = await fetch("https://usebasin.com/f/2529bbac89fc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmissionStatus({
          isSubmitting: false,
          isSuccess: true,
          isError: false,
          message:
            '<p class="history--testimonial--quote">Thank you for reaching out! 🚀 <br> I will be in touch within 48 hours.</p>',
        });
        updateHeading("Thank You!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      setSubmissionStatus({
        isSubmitting: false,
        isSuccess: false,
        isError: true,
        message: "Oops, something went wrong 🚨 <br> Please try again later.",
      });
      updateHeading("🤖 Error!!! 🤖");
    }
  };

  return (
    <FormContainer $viewport={viewport}>
      <Form
        theme={theme}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        $viewport={viewport}
      >
        {submissionStatus.message && (
          <MessageDiv
            theme={theme}
            isSuccess={submissionStatus.isSuccess}
            isError={submissionStatus.isError}
            dangerouslySetInnerHTML={{ __html: submissionStatus.message }}
          />
        )}
        {!submissionStatus.isSuccess && (
          <StyledForm id="contact-form" onSubmit={handleSubmit}>
            <FormField $viewport={viewport}>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                theme={theme}
                placeholder="Name"
                $viewport={viewport}
              />
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                theme={theme}
                placeholder="Email"
                $viewport={viewport}
              />
            </FormField>
            <FormField>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                theme={theme}
                placeholder="Message"
              />
            </FormField>
            <StyledButton
              type="submit"
              disabled={submissionStatus.isSubmitting}
              theme={theme}
            >
              {submissionStatus.isSubmitting ? "Submitting..." : "Submit"}
            </StyledButton>
          </StyledForm>
        )}
      </Form>
    </FormContainer>
  );
}

export default ContactForm;
