import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { typography } from "../../../styles/abstracts/typography";
import { hexToRgba } from "../../../utils/hexToRGBA";

export const FormContainerContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 0.5)};
  border: 2px solid ${({ theme }) => theme.secondaryColor};

  && {
    p {
      color: ${({ theme }) => theme.textColorPrimary};
    }
  }

  &:hover {
    transform: none;
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;

    p {
      font-size: ${typography.fontSizeMedium};
    }
  `}

  ${media.between("laptopL", "desktop")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    p {
      font-size: ${typography.fontSizeSmall};
    }
  `}

  ${media.between("laptopS", "laptopL")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.down("tabletM")`
    padding: 1rem 1rem 0.25rem 1rem;
    
    p {
      font-size: ${typography.fontSizeXs};
    }
  `}
`;

export const FormContainerContentWrapper = styled(BentoItem)`
  grid-column: 1 / span 3;
  grid-row: 3 / span 5;
  height: 100%;
  z-index: 10;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 1 / span 4;
  `}

  ${media.between("laptopS", "laptopL")`
    grid-column: 1 / span 3;
  `}

  ${media.between("tabletL", "laptopS")`
    grid-column: 1 / -1;
    grid-row:auto;
  `}

  ${media.between("tabletS", "tabletL")`
    grid-column: 1 / span 3;
    grid-row: 1 /span 3;

    @media (orientation: landscape) {
      grid-column: 1;
      grid-row: 1 / span 3;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: 1 / span 3;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: 1 / span 3;
  `}

&& {
    h2 {
      position: relative;
      color: ${({ theme }) => theme.textColorPrimary};
      z-index: 10;

      ${media.up("desktop")`
      `}

      ${media.between("laptopL", "desktop")`
      `}

      ${media.between("laptopS", "laptopL")`
      `}

      ${media.between("tabletS", "laptopS")`

        @media (orientation: landscape) {
        }
      `}

      ${media.between("mobileL", "tabletS")`
      `}

      ${media.down("mobileL")`
        font-size: ${typography.fontSizeLarge};
        padding-bottom: 0.5rem;
      `}
    }
  }
`;
