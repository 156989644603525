import React from "react";
import {
  CareerHighlightsContent,
  CareerHighlightsContentWrapper,
} from "./CareerHighlights.styles";

function CareerHighlights() {
  return (
    <CareerHighlightsContentWrapper disableBackground={true}>
      <h2>Career Highlights</h2>
      <CareerHighlightsContent>
        <ul>
          <li>Fractional CMO for multiple 7-figure launches</li>
          <li>Internationally published photographer</li>
          <li>Award-winning print and web designer</li>
          <li>Self-taught full-stack developer</li>
        </ul>
      </CareerHighlightsContent>
    </CareerHighlightsContentWrapper>
  );
}

export default CareerHighlights;
