import React, { useMemo } from "react";
import { BentoItem, BentoWrapper } from "../BentoBox/BentoBox";
import useStore from "../../stores/store";
import styled from "styled-components";
import { Wrapper } from "../BentoBox/StyledComponents";
import { media } from "../../styles/breakpoints";
import { useViewport } from "../../context/ViewportContext";

const DashboardWrapper = styled(Wrapper)`
  pointer-events: none;
  backdrop-filter: blur(0);
  -webkit-backdrop-filter: blur(0);

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
    grid-template-columns: 5.25rem 1fr;
    grid-template-rows: 5.25rem 1fr 5.25rem;
  `}

  ${media.between("tabletL", "laptopS")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr 3.5rem;

    @media (orientation: landscape) {
      padding-right: 0;
      grid-template-rows: 5.25rem 1fr 3.5rem;
      grid-template-columns: 5.25rem 1fr;
    }
  `}

  ${media.between("tabletS", "tabletL")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr 3.5rem;

    @media (orientation: landscape) {
      padding-right: 0;
      grid-template-rows: 2.75rem 1fr 3.5rem;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr 3.5rem;
    @media (orientation: landscape) {
      padding-right: 0;
    grid-template-rows: 2.75rem 1fr 3.5rem;
    }
  `}

  ${media.down("mobileL")`
  `}
`;

const BentoGrid = styled(BentoWrapper)`
  padding: 0;
  grid-column: 2;
  grid-row: 2 /-1;
  grid-template-rows: repeat(9, 1fr);

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
  `}

  ${media.between("tabletM", "laptopL")`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  `}

  ${media.down("mobileL")`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  `}
`;

const Intro = styled.div`
  grid-column: 1/-1;
  grid-row: 1;
  align-self: start;
  justify-self: start;

  h1 {
    font-size: 6.5vw;
    line-height: 4.5vw;
    margin-top: 0;
    color: ${({ theme }) => theme.primaryColor};

    ${media.up("desktop")`
  `}

    ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletM", "laptopS")`
  `}

  ${media.between("mobileL", "tabletM")`
      font-size: 10vw;
    line-height: 7.5vw;
  `}

  ${media.down("mobileL")`
      font-size: 10vw;
    line-height: 7.5vw;
  `}
  }
`;
const Pitch = styled.div`
  grid-column: 1/-1;
  grid-row: 2;
  align-self: end;
  justify-self: end;
`;

const SmallText = styled.h1`
  && {
    font-size: 2.75vw;
    line-height: 2vw;
    text-align: right;
    margin: 0 0 0.45rem 0;
    color: ${({ theme }) => theme.primaryColor};

    ${media.up("desktop")`
  `}

    ${media.between("laptopS", "desktop")`
    font-size: 2.75vw;
    line-height: 1.5vw;

  `}

  ${media.between("tabletM", "laptopS")`
    font-size: 3.125vw;
    line-height: 2vw;
  `}

  ${media.between("mobileL", "tabletM")`
    font-size: 3.125vw;
    line-height: 2vw;
  `}

  ${media.down("mobileL")`
    font-size: 3vw;
    line-height: 1vw;
  `}
  }
`;

const LargeText = styled.h1`
  && {
    margin: 0;
    font-size: 4vw;
    line-height: 3vw;
    text-transform: uppercase;
    color: ${({ theme }) => theme.primaryColor};

    ${media.up("desktop")`

  `}

    ${media.between("laptopM", "desktop")`
    font-size: 7vw;
    line-height: 5vw;
  `}

  ${media.between("tabletM", "laptopM")`
    font-size: 7vw;
    line-height: 5vw;
  `}

  ${media.between("mobileL", "tabletM")`
    font-size: 7vw;
    line-height: 5.75vw;
  `}

  ${media.down("mobileL")`
    font-size: 11vw;
    line-height: 8vw;
  `}
  }
`;

const Item1 = styled(BentoItem)`
  grid-column: span 3;
  grid-row: span 1;
  height: 100%;
  width: 100%;
  text-align: left;
  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: span 5;
    grid-row: span 1;
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: span 3;
    grid-row: span 1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: span 3;
    grid-row: span 1;
  `}

  ${media.down("mobileL")`
    grid-column: span 3;
    grid-row: span 1;
  `}
`;
const Item2 = styled(BentoItem)`
  background-color: white;
  grid-column: span 2;
  grid-row: span 3;
  height: 100%;
  width: 100%;
`;
const Item3 = styled(BentoItem)`
  background-color: white;
  grid-column: span 4;
  grid-row: span 3;
  height: 100%;
  width: 100%;
`;
const Item4 = styled(BentoItem)`
  background-color: white;
  grid-column: span 2;
  grid-row: span 6;
  height: 100%;
  width: 100%;
`;
const Item5 = styled(BentoItem)`
  background-color: white;
  grid-column: span 4;
  grid-row: span 3;
  height: 100%;
  width: 100%;
`;
const Item6 = styled(BentoItem)`
  grid-column: span 3;
  grid-row: span 3;
  height: 100%;
  width: 100%;
  background-color: white;
`;
const Item7 = styled(BentoItem)`
  grid-column: span 2;
  grid-row: span 3;
  height: 100%;
  width: 100%;
  background-color: white;
`;
const Item8 = styled(BentoItem)`
  grid-column: 5 / span 5;
  grid-row: 7 / span 3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: right;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 1/-1;
    grid-row: 4 / span 3;
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: span 3;
    grid-row: span 3;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: span 3;
    grid-row: span 3;
  `}

  ${media.down("mobileL")`
    grid-column: span 3;
    grid-row: span 3;
  `}
`;

function HomeDashboard() {
  const { theme } = useStore();
  const viewport = useViewport();
  const { width, height, isLandscape } = viewport;

  return (
    <DashboardWrapper disableBackground className="DASHBOARD_WRAPPER">
      <BentoGrid className="BENTOGRID">
        <Item1 disableBackground>
          <LargeText>Hi, I'm David</LargeText>
        </Item1>

        <Item8 disableBackground>
          <SmallText theme={theme}>I support businesses with</SmallText>
          <LargeText theme={theme}>Creative Development</LargeText>
          <LargeText theme={theme}>& Marketing Services</LargeText>
        </Item8>
      </BentoGrid>
    </DashboardWrapper>
  );
}

export default HomeDashboard;
