import React from "react";
import {
  ProjectPhasesContent,
  ProjectPhasesContentWrapper,
} from "./ProjectPhases.styles";

function ProjectPhases() {
  return (
    <ProjectPhasesContentWrapper disableBackground={true}>
      <h2>Project Phases:</h2>
      <ProjectPhasesContent>
        <p>
          Garage analogies aside (for now), here is the high-level roadmap for
          this site:
        </p>
        <ol>
          <li>
            <strong>Revival:</strong>
            <p>
              Recovering from a hack that corrupted my 20-year-old WordPress
              site by building a new, custom backend framework, and creating a
              temporary frontend as triage.
            </p>
          </li>
          <li>
            <strong>Reconstruction (current):</strong>
            <p>
              Launching and iterating on the new frontend framework. A signal
              that the crisis is nearly over, and I'm about ready to touch grass
              again.
            </p>
          </li>
          <li>
            <strong>Expansion:</strong>
            <p>
              Leveraging the 3D engine running behind the scenes to showcase
              content as immersive, interactive experiences in WebGL, AR & VR.
            </p>
          </li>
          <li>
            <strong>Integration:</strong>
            <p>
              The final phase involves connecting this site with other platforms
              and tools I use daily. It'll be like giving my digital brain an
              exoskeleton.
            </p>
          </li>
        </ol>
      </ProjectPhasesContent>
    </ProjectPhasesContentWrapper>
  );
}

export default ProjectPhases;
