import React from "react";
import {
  OpenToWrapper,
  OpenToItem,
  OpenToContentWrapper,
} from "./OpenTo.styles";

function OpenTo({ items }) {
  return (
    <OpenToContentWrapper disableBackground={true}>
      <h2>Open to...</h2>
      <OpenToWrapper>
        {items.map((item, index) => (
          <OpenToItem key={index}>{item}</OpenToItem>
        ))}
      </OpenToWrapper>
    </OpenToContentWrapper>
  );
}

export default OpenTo;
