import React from "react";
import {
  CollaborateContent,
  CollaborateContentWrapper,
} from "./Collaborate.styles";
import Button from "../../Button/Button";

function Collaborate() {
  return (
    <CollaborateContentWrapper disableBackground={true}>
      <h2>Let's Collaborate.</h2>
      <CollaborateContent>
        <p>
          If you're a small business or entrepreneur looking to make your mark,
          I am always open to interesting collaborations.
        </p>

        <Button to="/contact">Get in Touch</Button>
      </CollaborateContent>
    </CollaborateContentWrapper>
  );
}

export default Collaborate;
