import React from "react";
import {
  AboutAvatarContent,
  AboutAvatarContentWrapper,
  StyledAvatar,
} from "./AboutAvatar.styles";
import { useViewport } from "../../../context/ViewportContext";
import styled from "styled-components";
import useStore from "../../../stores/store";

function AboutAvatar() {
  const viewport = useViewport();
  const { theme } = useStore();

  return (
    <AboutAvatarContentWrapper disableBackground={true}>
      <AboutAvatarContent>
        <StyledAvatar theme={theme} $viewport={viewport} />
      </AboutAvatarContent>
    </AboutAvatarContentWrapper>
  );
}

export default AboutAvatar;
