import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import "./stylesGameDev.scss";
import useStore from "../stores/store";
import {
  BentoBox,
  BentoItem,
  BentoWrapper,
} from "../components/BentoBox/BentoBox";
import breakpoints, { media } from "../styles/breakpoints";
import styled from "styled-components";
import {
  Wrapper,
  Content,
  ScrollContent,
  ContentWrapper,
  StyledImage,
} from "../components/BentoBox/StyledComponents";
import Scrollbar from "../components/Scrollbar/Scrollbar";
import Hero from "../components/BentoBox/Hero";
import { useViewport } from "../context/ViewportContext";
import { preloadBackgroundImages, preloadImages } from "../utils/preloadImages";
import SplitContent from "../experiences/Photography/Imprisoned/SplitContent";

const Title = styled.h1`
  && {
    font-size: calc(4.5 * (1vh + 1vw));
    line-height: calc(3.25 * (1vh + 1vw));
    text-align: right;

    ${media.between("laptopL", "desktop")`
      font-size: calc(5.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
    `}

    ${media.between("laptopS", "laptopL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletL", "laptopS")`
      font-size: calc(3.75 * (1vh + 1vw));
      line-height: calc(3 * (1vh + 1vw));
    `}

    ${media.between("tabletM", "tabletL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletS", "tabletM")`
      font-size: clamp(2.5rem, 10vw + 1rem, 10.5rem);
      line-height: clamp(3.25rem, 3vw + 1rem, 5.5rem);
    `}


    ${media.down("tabletS")`
      font-size: calc(4.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
      margin-bottom: 0;
    `}
  }
`;

const GridLayout = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-column: 1/-1;
  grid-row: auto;

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    margin-top: 0;
  `}
`;

const Grid5x1 = styled(GridLayout)`
  grid-template-columns: repeat(5, 1fr);

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const Grid3x2 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const SplitLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1/-1;

  ${media.down("tabletM")`
    grid-template-columns: 1fr;
  `}
`;

const SplitMainAndSidebar = styled(SplitLayout)`
  grid-template-columns: ${(props) =>
    props.reverseOrder ? "1fr 1fr" : "1fr 1fr"};
  direction: ${(props) => (props.reverseOrder ? "rtl" : "ltr")};

  > * {
    direction: ltr; // Reset direction for children
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}
`;

const Grid1x1 = styled(GridLayout)`
  grid-template-columns: 1fr;
  grid-column: 1;

  ${media.down("tabletS")`
    grid-template-columns: 1fr;
  `}
`;

const Grid2x2 = styled(GridLayout)`
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/-1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletM", "laptopS")`
    grid-template-columns: 1fr;
    grid-column: 1/-1;
    margin-right: 0.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    grid-template-columns: 1fr;
  `}
`;

const Grid3x3 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
  `}
`;

const FullWidthItem = styled(BentoItem)`
  grid-column: 1/-1;
  height: 100%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1/-1;
    margin-right: 0.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
  `}
`;

const GridItem = styled(BentoItem)`
  height: 100%;

  ${media.between("mobileL", "tabletL")`
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
  `}
`;

const MainContent = styled(BentoItem)`
  height: 100%;
  align-content: center;
  justify-self: center;
  width: 80%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    margin-right: 0.5rem;
    width: 95%;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
    width: 95%;
  `}
`;

const ImageItem = styled(BentoItem)`
  height: 100%;

  ${media.down("tabletS")`
    margin-right: 0.5rem;
  `}
`;

function GameDev() {
  const [isLoading, setIsLoading] = useState(true);
  const viewport = useViewport();
  const { width, height } = viewport;
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();

  const { images, fetchImages } = useStore();

  const contentKey = useMemo(() => {
    return `portrait-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowScrollbar(contentHeight > containerHeight);
    }
  }, []);

  useEffect(() => {
    console.log("Portraiture component mounted");
    const loadImagesAndPreload = async () => {
      try {
        await fetchImages("photography", "imprisoned");
        await Promise.all([
          preloadImages(".grid__img"),
          preloadBackgroundImages(".grid__img"),
        ]);
        console.log("Images preloaded");
        setIsLoading(false);
        window.scrollTo(0, 0);
        updateDimensions();
      } catch (error) {
        console.error("Error loading images:", error);
        setIsLoading(false);
      }
    };

    loadImagesAndPreload();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [fetchImages, updateDimensions]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  const leftMargin = useMemo(() => {
    if (!viewport) return "5.25rem";
    const { width } = viewport;

    if (width < breakpoints.mobileL) return "3rem";
    if (width < breakpoints.tabletL) return "3.5rem";
    if (width < breakpoints.laptopS) return "5.25rem";
    if (width < breakpoints.desktop) return "5.25rem";
    return "5.25rem";
  }, [viewport]);

  const gridImages = images.map((image) => image.file);

  return (
    <Wrapper>
      <Content>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          forceScrollbar={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: leftMargin,
            right: "0",
          }}
        >
          <ScrollContent>
            <ContentWrapper>
              <BentoBox>
                <Hero>
                  <Title>GameDev: Yr.2</Title>
                </Hero>
                <FullWidthItem>
                  <StyledImage src={gridImages[1]} />
                </FullWidthItem>
                <SplitContent headline="Prologue:" image={gridImages[0]}>
                  <p>
                    I never set out to be a developer. Hell, I actively avoided
                    it for years. When I stumbled into a game development hobby
                    in 2022, I didn't expect it to revolutionize my entire skill
                    set, <em>and</em> help me recover from two disasters.
                  </p>

                  <p>
                    But here we are, closing out my second year, and that's
                    exactly what's happened...
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 1: The Catalyst"
                  image={gridImages[0]}
                  imageOnLeft={false}
                >
                  <p>
                    I've always been on the visual side of marketing, with just
                    enough coding skills to be dangerous. In our industry, most
                    folks leverage things like WordPress templates and page
                    builders, tweaking from a preexisting foundation.
                  </p>
                  <p>It works, but it always left me feeling vulnerable.</p>
                  <p>
                    For most of my career, that vulnerability was no big deal.
                    I'd just outsource the stuff I couldn't handle, and I'd
                    built a fantastic remote team in Ukraine.
                  </p>
                  <p>And then I lost contact with them.</p>
                  <p>That was the November before the invasion.</p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 2: The Challenge"
                  image={gridImages[0]}
                >
                  <p>
                    I'm underselling it for the sake of brevity but, losing my
                    team to that war was devastating.
                  </p>
                  <p>Truly a wake-up call.</p>
                  <p>
                    After two decades of avoiding it, I needed to know I could
                    step in and work on our web assets even if I was the only
                    one left.
                  </p>
                  <p>
                    Ironically, I'd started tinkering with game development as a
                    hobby a few months earlier. Little did I know it would
                    become my lifeline.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 3: The Odyssey"
                  image={gridImages[0]}
                  imageOnLeft={false}
                >
                  <p>
                    The first few months in game dev were enough to make me
                    realize I'd need a serious study of programming if I wanted
                    to progress in the craft.
                  </p>
                  <p>
                    I spent most of 2023 building that foundation. By the end of
                    the year, I'd even earned two certificates from Harvard's CS
                    programs. But it still didn't feel like enough.
                  </p>
                  <p>
                    In 2024, I've used my web design background as a springboard
                    into more complex programming concepts, and beyond. The
                    result?
                  </p>
                  <p>
                    I've utterly obliterated the development weaknesses that
                    used to hold me back.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 4: The Challenge"
                  image={gridImages[0]}
                >
                  <p>
                    I'm underselling it for the sake of brevity but, losing my
                    team to that war was devastating.
                  </p>
                  <p>Truly a wake-up call.</p>
                  <p>
                    After two decades of avoiding it, I needed to know I could
                    step in and work on our web assets even if I was the only
                    one left.
                  </p>
                  <p>
                    Ironically, I'd started tinkering with game development as a
                    hobby a few months earlier. Little did I know it would
                    become my lifeline.
                  </p>
                </SplitContent>
              </BentoBox>
            </ContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </Content>
    </Wrapper>
  );
}

export default GameDev;
