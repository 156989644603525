import React from "react";
import { WhyContent, WhyContentWrapper } from "./Why.styles";

function Why() {
  return (
    <WhyContentWrapper disableBackground={true}>
      <h2>Why Build Like This?</h2>
      <WhyContent>
        <p>Why be willing to launch an incomplete project?</p>
        <p>
          Because "waiting until it's perfect" is the death knell of creativity.
          It's the siren song that's lured countless great ideas to their doom
          on the rocky shores of "someday."
        </p>
        <p>
          To sidestep that trap, and keep my relentless momentum, I choose to
          work with the garage doors wide open.
        </p>
        <p>The approach isn't for everyone. It's vulnerable. It's real.</p>
        <p>It's occasionally embarrassing.</p>
        <p>
          But it's also honest. And in a world where everyone's frantically
          polishing their online presence to a glimmering shine, I think it
          takes more courage to be a work in progress.
        </p>
      </WhyContent>
    </WhyContentWrapper>
  );
}

export default Why;
