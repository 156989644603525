import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { typography } from "../../../styles/abstracts/typography";
import { hexToRgba } from "../../../utils/hexToRGBA";

export const OpenToContentWrapper = styled(BentoItem)`
  grid-column: 8 / span 3;
  grid-row: 3 / span 2;
  height: fit-content;
  z-index: 10;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 6 / span 4;
    grid-row: 1;
  `}

  ${media.between("laptopS", "laptopL")`
    grid-column: 6 / span 4;
    grid-row: 1;
  `}

  ${media.between("tabletL", "laptopS")`
    grid-column: 7 / -1 ;
    grid-row: auto / span 2;

  `}

  ${media.between("tabletS", "tabletL")`
    grid-column: 7 / -1;
    grid-row: auto / span 2;

    @media (orientation: landscape) {
      grid-column: 1;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: auto / span 2;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: auto / span 2;
  `}
&& {
    h2 {
      position: relative;
      color: ${({ theme }) => theme.textColorPrimary};
      z-index: 10;
    }
  }
`;

export const OpenToContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 0.5)};
  border: 2px solid ${({ theme }) => theme.secondaryColor};

  && {
    p {
      color: ${({ theme }) => theme.textColorPrimary};
    }
  }

  &:hover {
    transform: none;
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;

    p {
      font-size: ${typography.fontSizeMedium};
    }
  `}

  ${media.between("laptopL", "desktop")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    p {
      font-size: ${typography.fontSizeSmall};
    }
  `}

  ${media.between("laptopS", "laptopL")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.down("tabletM")`
    padding: 1rem 1rem 0.25rem 1rem;
    
    p {
      font-size: ${typography.fontSizeXs};
    }
  `}
`;

export const OpenToWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const OpenToItem = styled.span`
  background-color: ${(props) => hexToRgba(props.theme.secondaryColor, 0.25)};
  color: ${(props) => props.theme.textColorPrimary};
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: ${typography.fontSizeSmall};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => hexToRgba(props.theme.secondaryColor, 0.5)};
    transform: translateY(-2px);
  }

  ${media.down("tabletM")`
    font-size: ${typography.fontSizeXs};
    padding: 0.3rem 0.8rem;
  `}
`;
