/**
 * SVGIcon.js
 *
 * This file provides a React component for efficiently rendering SVG icons from sprite sheets.
 * It allows easy incorporation and management of SVG icon colors in the application.
 */

import React, { useState, useEffect } from "react";

// Base URL for SVG sprite sheets, fallback to a default if not set in environment
const BASE_SVG_URL =
  process.env.REACT_APP_SVG_BASE_URL ||
  "https://site-davidbickley.s3.us-east-2.amazonaws.com/sprite-sheets/";

// Cache to store parsed sprite sheets
const spriteSheetCache = {};

// Tracker for ongoing fetch requests
const ongoingFetches = {};

/**
 * SVGIcon Component
 *
 * Renders an SVG icon from a sprite sheet, with caching for improved performance.
 *
 * @param {string} iconId - The ID of the specific icon in the sprite sheet
 * @param {string} spriteSheetName - The name of the sprite sheet file (without extension)
 * @param {string} [customViewBox] - Optional custom viewBox for the SVG
 * @param {string[]} [additionalClasses=[]] - Additional CSS classes to apply to the SVG
 * @param {string} [ariaLabel] - Accessibility label for the icon
 * @returns {React.Element} An SVG element containing the requested icon
 */
const SVGIcon = ({
  iconId,
  spriteSheetName,
  customViewBox,
  additionalClasses = [],
  ariaLabel,
}) => {
  // State to hold SVG content and viewBox
  const [svgData, setSvgData] = useState({
    svgContent: "",
    viewBox: customViewBox,
  });

  useEffect(() => {
    const cacheKey = spriteSheetName;

    // Function to update SVG data from parsed document
    const updateSvg = (doc) => {
      const svgSymbol = doc.querySelector(`#${iconId}`);
      if (svgSymbol) {
        setSvgData({
          svgContent: svgSymbol.innerHTML,
          viewBox: customViewBox || svgSymbol.getAttribute("viewBox"),
        });
      } else {
        console.error(`No SVG symbol found with the id ${iconId}`);
      }
    };

    // Use cached sprite sheet if available
    if (spriteSheetCache[cacheKey]) {
      updateSvg(spriteSheetCache[cacheKey]);
      return;
    }

    // Fetch sprite sheet if not already fetching
    if (!ongoingFetches[cacheKey]) {
      ongoingFetches[cacheKey] = fetch(`${BASE_SVG_URL}${spriteSheetName}.svg`)
        .then((response) => response.text())
        .then((spriteSheet) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(spriteSheet, "image/svg+xml");
          spriteSheetCache[cacheKey] = doc;
          return doc;
        })
        .catch((error) => {
          console.error("Error fetching SVG sprite sheet:", error);
          throw error;
        })
        .finally(() => {
          delete ongoingFetches[cacheKey];
        });
    }

    // Handle component unmount
    let isSubscribed = true;
    ongoingFetches[cacheKey]
      .then((doc) => {
        if (isSubscribed) updateSvg(doc);
      })
      .catch((error) => {
        if (isSubscribed)
          console.error("Error after fetching SVG sprite sheet:", error);
      });

    return () => {
      isSubscribed = false;
    };
  }, [iconId, spriteSheetName, customViewBox]);

  // Combine CSS classes
  const classes = ["svg-icon", ...additionalClasses].join(" ");

  // Render SVG
  return (
    <svg
      className={classes}
      viewBox={svgData.viewBox}
      dangerouslySetInnerHTML={{ __html: svgData.svgContent }}
      aria-label={ariaLabel}
    />
  );
};

export default SVGIcon;

// Usage example:
//
// import SVGIcon from './path/to/SVGIcon';
//
// function MyComponent() {
//   return (
//     <SVGIcon
//       iconId="my-icon"
//       spriteSheetName="icons-sprite"
//       additionalClasses={["large-icon", "blue-icon"]}
//       ariaLabel="My Icon"
//     />
//   );
// }
//
// This will render an SVG icon with id "my-icon" from the "icons-sprite.svg" sprite sheet.
// The icon will have additional classes "large-icon" and "blue-icon", and an aria-label for accessibility.
