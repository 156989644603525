// styles/abstracts/colors.js

export const colors = {
  grey: "#f4f3f7",
  greyDark: "#837a75",
  white: "#f8f8f8",
  black: "#121018",

  balsamic100: "#fcf7f8",
  balsamic200: "#f5e6e8",
  balsamic300: "#e3c1c6",
  balsamic400: "#d19ca5",
  balsamic500: "#bf7783",
  balsamic600: "#52001f",
  balsamic700: "#4a001b",
  balsamic800: "#3f0017",
  balsamic900: "#290010",
  balsamicBlack: "#1a000a",

  cyprus100: "#f7fbfc",
  cyprus200: "#9eeaec",
  cyprus300: "#63d4d8",
  cyprus400: "#2da4a8",
  cyprus500: "#23797c",
  cyprus600: "#104547",
  cyprus700: "#0f4143",
  cyprus800: "#0b3132",
  cyprus900: "#082021",
  cyprusBlack: "#041011",

  marigold100: "#f8f7f6",
  marigold200: "#ffe3a3",
  marigold300: "#ffd475",
  marigold400: "#fbbe4b",
  marigold500: "#fbb637",
  marigold600: "#faa916",
  marigold700: "#f09e05",
  marigold800: "#c88304",
  marigold900: "#a06903",

  blush100: "#fffcfc",
  blush200: "#f9dddd",
  blush300: "#f6cccc",
  blush400: "#f3bbbb",
  blush500: "#f0aaaa",
  blush600: "#ed9999",
  blush700: "#ea8888",
  blush800: "#e77777",
  blush900: "#e46666",

  raisin100: "#f0eef7",
  raisin200: "#bfb8d6",
  raisin300: "#8f84b5",
  raisin400: "#655a94",
  raisin500: "#4a4178",
  raisin600: "#3d3b6b",
  raisin700: "#2a2442",
  raisin800: "#1c1830",
  raisin900: "#100f1a",
  raisinBlack: "#070610",

  mono100: "#f4f4f4",
  mono200: "#ebebeb",
  mono300: "#d1d1d1",
  mono400: "#b7b7b7",
  mono500: "#9d9d9d",
  mono600: "#838383",
  mono700: "#696969",
  mono800: "#4f4f4f",
  mono900: "#121212",
};
