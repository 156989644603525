import React, {
  useRef,
  useLayoutEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  breakpoints,
  heightBreakpoints,
  media,
} from "../../styles/breakpoints";
import Scrollbar from "../Scrollbar/Scrollbar";
import useStore from "../../stores/store";
import { Wrapper, Content } from "../BentoBox/StyledComponents";
import { BentoBox, BentoItem, BentoWrapper } from "../BentoBox/BentoBox";
import Origin from "./Origin/Origin";
import CareerHighlights from "./CareerHighlights/CareerHighlights";
import AboutAvatar from "./AboutAvatar/AboutAvatar";
import AboutMethod from "./AboutMethod/AboutMethod";
import CareerSummary from "./CareerSummary/CareerSummary";
import Philosophy from "./Philosophy/Philosophy";
import MyServices from "./MyServices/MyServices";
import ProjectSummary from "./ProjectSummary/ProjectSummary";
import ProjectPhases from "./ProjectPhases/ProjectPhases";
import SiteProject from "./SiteProject/SiteProject";
import Why from "./Why/Why";
import Collaborate from "./Collaborate/Collaborate";

const ContentWrapper = styled(Wrapper)`
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: calc(97vh - 10rem);
  align-self: flex-start;
  overflow: hidden;

  ${media.up("desktop")`
    height: calc(100vh - 7rem);
  `}

  ${media.between("laptopS", "desktop")`
    height: calc(100vh - 10rem);
  `}

  ${media.between("tabletL", "laptopS")`
    height: calc(100vh - 7rem);
  `}

  ${media.between("tabletM", "tabletL")`
    height: calc(100vh - 7rem);
  `}

  ${media.between("mobileL", "tabletM")`
    height: calc(100vh - 5rem);
  `}
  ${media.between("mobileM", "mobileL")`
    height: calc(100vh - 10rem);
  `}

  ${media.down("mobileM")`
    height: calc(100vh - 10rem);
  `}
`;

const AboutBento = styled(BentoWrapper)`
  padding: 0.5rem 2rem 0 2rem;
  align-items: start;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletL", "laptopS")`
    grid-column: 1/-1;
    grid-row: auto;
    grid-template-columns: repeat(6, 1fr);
    
    @media (orientation: landscape) {
      padding: 0.5rem  1rem 0 1rem;
      grid-template-columns: 1fr;
    }
  `}

  ${media.between("tabletS", "tabletL")`
    grid-column: 1/-1;
    grid-row: auto;
    grid-template-columns: repeat(6, 1fr);
    
    @media (orientation: landscape) {
      padding: 0.5rem  1rem 0 1rem;
      grid-template-columns: 1fr;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    padding: 0 0 0 1rem;
    grid-template-columns: 1fr;
    grid-row: 2 /span 1;
  `}

  ${media.down("mobileL")`
    padding: 0 0 0 1rem;
    grid-template-columns: 1fr;
    grid-row: 2 /span 1;
  `}
`;

const AboutHeader = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.textColorPrimary || "#333"};
  margin: 0;
  padding: 1rem;
  flex-shrink: 0;
  grid-row: 1;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: 0.9rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        font-size: 1.05rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: 1.125rem;
        padding: 0 0 0 1rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        font-size: 1.25rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? `1.25rem` : `1.5rem`};
        padding: ${isLandscape ? `0.25rem 0.75rem` : `0.75rem`};
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        font-size: 1.25rem;
        padding: 0 0 0 1rem;
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        font-size: 2rem;
        padding: 0.5rem 0 0 1rem;
      `;
    } else {
      return `
        padding: 0 0 0 2rem;
      `;
    }
  }}
`;

const ScrollContent = styled.div`
  height: ${(props) => props.height || "auto"};
  padding-right: 20px;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletL", "laptopS")`
    @media (orientation: landscape) {
      padding-right: 0;
    }
  `}

  ${media.between("tabletS", "tabletL")`
    
    @media (orientation: landscape) {
      padding-right: 0;
    }
  `}

  ${media.between("mobileL", "tabletS")`
  `}

  ${media.down("mobileL")`
    padding-right: 1rem;
  `}
`;

const AboutContent = ({ viewport }) => {
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const scrollbarRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();
  const [scrollbarMaxHeight, setScrollbarMaxHeight] = useState("82vh");

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      // Force reflow
      void containerRef.current.offsetHeight;
      void contentRef.current.offsetHeight;

      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;

      // Add a small buffer (e.g., 1px) to account for potential rounding errors
      const shouldScroll = contentHeight > containerHeight + 1;
      setShouldShowScrollbar(shouldScroll);

      // Calculate dynamic max height
      const viewportHeight = window.innerHeight;
      const headerHeight = document.querySelector("h1")?.offsetHeight || 0;
      const availableHeight = viewportHeight - headerHeight;
      const maxHeightPercentage = Math.min(
        0.9,
        availableHeight / viewportHeight
      );
      const newMaxHeight = `${maxHeightPercentage * 100}vh`;
      setScrollbarMaxHeight(newMaxHeight);

      // Debug logs
      console.log("Viewport height:", viewportHeight);
      console.log("Header height:", headerHeight);
      console.log("Available height:", availableHeight);
      console.log("Max height percentage:", maxHeightPercentage);
      console.log("Scrollbar max height:", newMaxHeight);
    }
  }, []);

  const preventOverscroll = useCallback((event) => {
    const scrollbar = scrollbarRef.current;
    if (!scrollbar) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollbar;
    const isAtTop = scrollTop <= 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    if ((isAtTop && event.deltaY < 0) || (isAtBottom && event.deltaY > 0)) {
      event.preventDefault();
    }
  }, []);

  useLayoutEffect(() => {
    updateDimensions();
    const handleResize = () => {
      updateDimensions();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [updateDimensions]);

  useLayoutEffect(() => {
    const scrollbar = scrollbarRef.current;
    if (scrollbar) {
      scrollbar.addEventListener("wheel", preventOverscroll, {
        passive: false,
      });
      scrollbar.addEventListener("touchmove", preventOverscroll, {
        passive: false,
      });
    }

    return () => {
      if (scrollbar) {
        scrollbar.removeEventListener("wheel", preventOverscroll);
        scrollbar.removeEventListener("touchmove", preventOverscroll);
      }
    };
  }, [preventOverscroll]);

  useLayoutEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  useLayoutEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    const handleResize = () => {
      updateDimensions();
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    window.addEventListener("resize", handleResize);

    const scrollbar = scrollbarRef.current;
    if (scrollbar) {
      scrollbar.addEventListener("wheel", preventOverscroll, {
        passive: false,
      });
      scrollbar.addEventListener("touchmove", preventOverscroll, {
        passive: false,
      });
    }

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
      window.removeEventListener("resize", handleResize);
      if (scrollbar) {
        scrollbar.removeEventListener("wheel", preventOverscroll);
        scrollbar.removeEventListener("touchmove", preventOverscroll);
      }
    };
  }, [updateDimensions, preventOverscroll]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });
    if (containerRef.current) resizeObserver.observe(containerRef.current);
    if (contentRef.current) resizeObserver.observe(contentRef.current);

    return () => resizeObserver.disconnect();
  }, [updateDimensions]);

  const contentKey = useMemo(() => {
    return `bio-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  return (
    <>
      <AboutHeader $viewport={viewport} theme={theme}>
        [ Hi, I'm David ]
      </AboutHeader>

      <ContentWrapper
        $viewport={viewport}
        theme={theme}
        ref={containerRef}
        className="CONTENT_WRAPPER"
      >
        <Scrollbar
          key={`${orientationKey}-${contentKey}`}
          theme={theme}
          scrollbarPosition="left"
          applyScrolling={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
          style={{ height: "100%" }}
          ref={scrollbarRef}
        >
          <ScrollContent ref={contentRef} $viewport={viewport} theme={theme}>
            <AboutBento>
              <Origin />
              <MyServices />
              <CareerHighlights />
              <Philosophy />
              <CareerSummary />
              <AboutAvatar $viewport={viewport} />
              <ProjectSummary />
              <SiteProject />
              <ProjectPhases />
              <Why />
              <Collaborate />
            </AboutBento>
          </ScrollContent>
        </Scrollbar>
      </ContentWrapper>
    </>
  );
};

export default AboutContent;
