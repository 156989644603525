import React from "react";
import {
  FormContainerContent,
  FormContainerContentWrapper,
} from "./FormContainer.styles";

function FormContainer({ headline, children }) {
  return (
    <FormContainerContentWrapper disableBackground={true}>
      <h2>{headline}</h2>
      <FormContainerContent>{children}</FormContainerContent>
    </FormContainerContentWrapper>
  );
}

export default FormContainer;
