// services/ProjectService.js
import axios from "axios";

const BASE_URL = "/api/projects/";

export const fetchProjects = async () => {
  try {
    const { data } = await axios.get(BASE_URL);
    return data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};

export const fetchProjectsByStatus = async (status) => {
  try {
    const response = await axios.get(`${BASE_URL}?status=${status}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching projects with status ${status}:`, error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error request:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error message:", error.message);
    }
    throw error;
  }
};

export const fetchProjectsByCategory = async (category) => {
  try {
    const response = await axios.get(`${BASE_URL}?category=${category}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching projects with category ${category}:`, error);
    throw error;
  }
};
export const fetchFeaturedProjects = async () => {
  try {
    const response = await axios.get(`${BASE_URL}?is_featured=true`);
    return response.data;
  } catch (error) {
    console.error("Error fetching featured projects:", error);
    throw error;
  }
};
