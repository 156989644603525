// context/ViewportContext.jsx

import React, { createContext, useState, useEffect, useContext } from "react";
import debounce from "lodash/debounce";
import { breakpoints } from "../styles/breakpoints";

/**
 * Viewport Context
 *
 * Provides a React context and custom hook for managing viewport information.
 * It allows components to access current viewport details from anywhere in the component tree.
 */

// Create a context for the viewport information
const ViewportContext = createContext();

/**
 * ViewportProvider component
 *
 * This component wraps all or part of the app to provide viewport information to all child components.
 * It listens for window resize and orientation change events to keep the viewport info up-to-date.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components
 */
export const ViewportProvider = ({ children }) => {
  // Initialize viewport state with current viewport info
  const [viewport, setViewport] = useState(() => getViewportInfo());

  useEffect(() => {
    // Debounced function to update viewport info on resize
    const handleResize = debounce(() => {
      setViewport(getViewportInfo());
    }, 250); // 250ms debounce time

    // Function to update viewport info on orientation change
    const handleOrientationChange = () => {
      setViewport(getViewportInfo());
    };

    // Add event listeners
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  // Provide the viewport information to all children
  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  );
};

/**
 * Custom hook to use the viewport information
 *
 * This hook allows components to easily access the current viewport information.
 *
 * @returns {Object} An object containing current viewport details
 * @throws {Error} If used outside of a ViewportProvider
 */
export const useViewport = () => {
  const context = useContext(ViewportContext);
  if (!context) {
    throw new Error("useViewport must be used within a ViewportProvider");
  }
  return context;
};

/**
 * Helper function to get current viewport information
 *
 * @returns {Object} An object containing various viewport details
 */
function getViewportInfo() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isLandscape = width > height;

  let deviceType = "desktop";
  if (width <= breakpoints.mobileL) {
    deviceType = "mobile";
  } else if (width <= breakpoints.tabletL) {
    deviceType = "tablet";
  }

  return {
    width,
    height,
    isLandscape,
    deviceType,
    isMobile: deviceType === "mobile",
    isTablet: deviceType === "tablet",
    isDesktop: deviceType === "desktop",
  };
}

// Usage example:
//
// import { ViewportProvider, useViewport } from './context/ViewportContext';
//
// // In app's root component:
// function App() {
//   return (
//     <ViewportProvider>
//       {/* Your app components */}
//     </ViewportProvider>
//   );
// }
//
// // In any child component:
// function ResponsiveComponent() {
//   const { width, height, deviceType, isLandscape } = useViewport();
//
//   return (
//     <div>
//       <p>Width: {width}</p>
//       <p>Height: {height}</p>
//       <p>Device: {deviceType}</p>
//       <p>Orientation: {isLandscape ? 'Landscape' : 'Portrait'}</p>
//     </div>
//   );
// }
