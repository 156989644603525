// styles/abstracts/typography.js

export const typography = {
  fontHeadline: '"Zilla Slab", Georgia, "Times New Roman", Times, serif',
  fontSubheadline: '"Zilla Slab", Georgia, "Times New Roman", Times, serif',
  fontBody: '"Montserrat", Arial, Helvetica, sans-serif',
  fontSignature: '"Permanent Marker"',
  fontSizeMin: "0.5rem", // 10px
  fontSizeXxxs: "0.625rem", // 10px
  fontSizeXxs: "0.75rem", // 12px
  fontSizeXs: "0.875rem", // 14px
  fontSizeSmall: "1rem", // 16px
  fontSizeMedium: "1.25rem", // 20px
  fontSizeLarge: "1.5rem", // 24px
  fontSizeXl: "2rem", // 32px
  fontSizeXxl: "3rem", // 48px
  fontHeightXxxs: "0.9rem",
  fontHeightXxs: "1rem",
  fontHeightXs: "1.25rem",
  fontHeightSm: "1.5rem", // 24px
  fontHeightMd: "1.75rem", // 28px
  fontHeightLg: "2rem", // 32px
};
