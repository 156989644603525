import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { typography } from "../../../styles/abstracts/typography";

export const OriginContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;

  p {
    color: ${({ theme }) => theme.textColorPrimary};
    font-size: ${typography.fontSizeMedium};
  }

  &:hover {
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 0.5rem 2rem 1rem 0.5rem;
  `}

  ${media.between("laptopL", "desktop")`
    padding: 1rem 1.25rem 0.5rem 0.5rem;

    ul, li, p {
      font-size: ${typography.fontSizeSmall};
    }
  `}

  ${media.between("laptopS", "laptopL")`
    padding: 1rem 1.25rem 0.5rem 0.5rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 0.5rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 0.25rem 0.5rem 0.5rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}
`;

export const OriginContentWrapper = styled(BentoItem)`
  grid-column: 1 / span 6;
  grid-row: 1 / span 2;
  align-self: center;
  margin-top: 1rem;
  height: fit-content;
  z-index: 10;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 1 / span 5;
    margin-top: 0;
    align-self: start;
  `} 

  ${media.between("laptopS", "laptopL")`
    grid-column: 1 / span 4;
    margin-top: 0;
    align-self: start;
  `} 
  
  ${media.between("tabletS", "laptopS")`
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;

    @media (orientation: landscape) {
      grid-column: 1;
      grid-row: 3 / span 2;
    }
  `} 
  
  ${media.between("mobileL", "tabletS")`
    grid-column: 1 ;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    grid-row: 2;
  `} 
  
  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: 2;
    margin-right: 0.5rem;
    margin-top: 0;
  `} 
  
  h2 {
    position: relative;
    z-index: 10;
  }
`;
