import React, { Suspense, lazy } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import UI from "./components/UI/UI";
import Home from "./pages/Home";
import About from "./components/About/About";
import Projects from "./components/Project/Projects";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import CaseStudies from "./pages/CaseStudies";
import CaseStudyDetail from "./components/CaseStudy/CaseStudyDetail";
import GameDev from "./pages/GameDev";
import useStore from "./stores/store";
import Portraiture from "./experiences/Photography/Portraiture/Portraiture";
import ProjectsDetails from "./components/Project/ProjectDetails";
import Fitness from "./experiences/Photography/Fitness/Fitness";
import Imprisoned from "./experiences/Photography/Imprisoned/Imprisoned";

const Dodecahedron = lazy(() => import("./experiences/Dodecahedron"));

function AppContent() {
  const location = useLocation();
  const { theme } = useStore();

  return (
    <StyledThemeProvider theme={theme}>
      <div className="app-container">
        <UI>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />}>
              <Route index element={<About tab="bio" />} />
              <Route path=":tab" element={<About />} />
            </Route>
            <Route path="/projects" element={<Projects />}>
              <Route index element={<Projects />} />
              <Route path=":category" element={<Projects />} />
            </Route>
            <Route
              path="/projects/editorial-portraiture"
              element={<Portraiture />}
            />
            <Route path="/projects/bodies-of-work" element={<Fitness />} />
            <Route path="/projects/imprisoned" element={<Imprisoned />} />
            <Route
              path="/projects/:projectSlug"
              element={<ProjectsDetails />}
            />
            <Route
              path="/experience/:experienceId?/:tab?"
              element={<Resume />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route
              path="/case-studies/:caseStudySlug"
              element={<CaseStudyDetail />}
            />
            {/* <Route path="/game-dev" element={<GameDev />} /> */}
          </Routes>
        </UI>
        <Suspense fallback={<div>Loading...</div>}>
          <Dodecahedron />
        </Suspense>
      </div>
    </StyledThemeProvider>
  );
}

export default AppContent;
