// services/TechnologyService.js
import axios from 'axios';

const BASE_URL = '/api/technologies/';

export const fetchTechnologies = async () => {
  try {
    const { data } = await axios.get(BASE_URL);
    return data;
  } catch (error) {
    console.error('Error fetching technologies:', error);
    throw error;
  }
};