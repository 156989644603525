// components/Menu/Menu.styled.js

import styled from "styled-components";
import { motion } from "framer-motion";
import { breakpoints } from "../../styles/breakpoints";

//  removed height: 100% from MenuWrapper, if weird things happen, put it back;
export const MenuWrapper = styled.div`
  position: absolute;
  z-index: 999;
  pointer-events: auto;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        right: 0.35rem;
        top: 0.35rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        right: 0.35rem;
        top: 0.35rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        right: 0.35rem;
        top: 0.35rem;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        right: 0.4rem;
        top: 0.4rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        right: ${isLandscape ? "0.25rem" : "0.5rem"};
        top: ${isLandscape ? "0.25rem" : "0.5rem"};
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        right: ${isLandscape ? "0.25rem" : "0.5rem"};
        top: ${isLandscape ? "0.25rem" : "0.5rem"};
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        right: 0.6rem;
        top: 0.6rem;
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        right: 0.65rem;
        top: 0.65rem;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        right: 0.7rem;
        top: 0.7rem;
      `;
    }
    return `
      right: 0.75rem;
      top: 0.75rem;
    `;
  }}
`;

export const StyledMenu = styled(motion.div)`
  position: relative;
  background-color: ${({ theme }) => theme.primaryColor};
  overflow: hidden;
`;

export const menu = {
  open: ($viewport) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return {
        width: "100vw",
        height: isLandscape ? "101vh" : "95vh",
        top: "-15px",
        right: "-15px",
        borderRadius: "0",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.mobileM) {
      return {
        width: "100vw",
        height: isLandscape ? "101vh" : "99vh",
        top: "-20px",
        right: "-15px",
        borderRadius: "0",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.mobileL) {
      return {
        width: "100vw",
        height: isLandscape ? "101vh" : "100vh",
        top: "-25px",
        right: "-15px",
        borderRadius: "0",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.tabletS) {
      return {
        width: "100vw",
        height: isLandscape ? "100vh" : "100vh",
        top: "-20px",
        right: "-15px",
        borderRadius: "0",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.tabletM) {
      return {
        width: isLandscape ? "100vw" : "60vw",
        height: isLandscape ? "101vh" : "70vh",
        top: "-20px",
        right: "-15px",
        borderRadius: isLandscape ? "10px 0 0 10px" : "10px 0 0 10px",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.tabletL) {
      return {
        width: isLandscape ? "99vw" : "50vw",
        height: isLandscape ? "99vh" : "50vh",
        top: "-15px",
        right: "-15px",
        borderRadius: isLandscape ? "10px 0 0 10px" : "10px 0 0 10px",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.laptopS) {
      return {
        width: isLandscape ? "45vw" : "50vw",
        height: `max(500px, 60vh)`,
        top: "-20px",
        right: "-20px",
        borderRadius: "10px 0 0 20px",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.laptopM) {
      return {
        width: isLandscape ? "50vw" : "50vw",
        height: `max(600px, 60vh)`,
        top: "-22px",
        right: "-22px",
        borderRadius: "10px 0 0 22px",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    if (width <= breakpoints.laptopL) {
      return {
        width: isLandscape ? "50vw" : "50vw",
        height: `max(600px, 60vh)`,
        top: "-24px",
        right: "-24px",
        borderRadius: "10px 0 0 24px",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
      };
    }
    return {
      width: "480px",
      height: "650px",
      top: "-25px",
      right: "-25px",
      borderRadius: "10px 10px 10px 25px",
      transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1] },
    };
  },
  closed: ($viewport) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return {
        width: "60px",
        height: "30px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.mobileM) {
      return {
        width: isLandscape ? "55px" : "65px",
        height: isLandscape ? "30px" : "35px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.mobileL) {
      return {
        width: "70px",
        height: "35px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.tabletS) {
      return {
        width: isLandscape ? "60px" : "80px",
        height: isLandscape ? "30px" : "40px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.tabletM) {
      return {
        width: "65px",
        height: "30px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.tabletL) {
      return {
        width: "70px",
        height: "35px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.laptopS) {
      return {
        width: "90px",
        height: "38px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    if (width <= breakpoints.laptopM) {
      return {
        width: "95px",
        height: "39px",
        top: "0px",
        right: "0px",
        borderRadius: "25px",
        transition: {
          duration: 0.75,
          delay: 0.35,
          type: "tween",
          ease: [0.76, 0, 0.24, 1],
        },
      };
    }
    return {
      width: "100px",
      height: "40px",
      top: "0px",
      right: "0px",
      borderRadius: "25px",
      transition: {
        duration: 0.75,
        delay: 0.35,
        type: "tween",
        ease: [0.76, 0, 0.24, 1],
      },
    };
  },
};
