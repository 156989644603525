import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { typography } from "../../../styles/abstracts/typography";

export const PhilosophyContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: ${({ theme }) => theme.secondaryColor};

  p {
    color: ${({ theme }) => theme.textColorLight};
    font-size: ${typography.fontSizeSmall};
  }

  &:hover {
    background-color: ${({ theme }) => theme.tertiaryColor};
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
    ul, li, p {
      font-size: ${typography.fontSizeSmall};
    }
  `}

  ${media.between("laptopL", "desktop")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    ul, li, p {
      font-size: ${typography.fontSizeSmall};
    }
  `}

  ${media.between("laptopS", "laptopL")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;

    p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.down("tabletM")`
    padding: 1rem 1rem 0.25rem 1rem;
    
    p {
      font-size: ${typography.fontSizeXs};
    }
  `}
`;

export const PhilosophyContentWrapper = styled(BentoItem)`
  grid-column: 5 / span 4;
  grid-row: 4;
  height: fit-content;
  z-index: 10;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 6 / span 4;
  `}

  ${media.between("laptopS", "laptopL")`
    grid-column: 1 / -1;
    grid-row: auto;
  `}

  ${media.between("tabletS", "laptopS")`
    grid-column: 1 / -1;
    grid-row: auto;
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: auto;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: auto;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;
