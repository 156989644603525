import React from "react";
import {
  ContactInterestsContent,
  ContactInterestsContentWrapper,
} from "./ContactInterests.styles";

function ContactInterests({ items }) {
  return (
    <ContactInterestsContentWrapper disableBackground={true}>
      <h2>Current Interests:</h2>
      <ContactInterestsContent>
        <ul>
          <li>Immersive 3D Web Experiences</li>
          <li>Emergent storytelling techniques</li>
          <li>Digital twins and spatial computing in marketing experiences</li>
        </ul>
      </ContactInterestsContent>
    </ContactInterestsContentWrapper>
  );
}

export default ContactInterests;
