import styled from "styled-components";
import { media } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import { hexToRgba } from "../../utils/hexToRGBA";
import Button from "../Button/Button";

export const FormContainer = styled.div`
  padding: 0.5rem;
  pointer-events: auto;
`;

export const Form = styled.div`
  position: relative;

  ${media.up("desktop")`
    // Styles for desktop and above
  `}

  ${media.between("laptopL", "desktop")`
    // Styles for large laptops to desktops
  `}

  ${media.between("laptopS", "laptopL")`
    // Styles for small laptops to large laptops
  `}

  ${media.between("tabletL", "laptopS")`
    // Styles for large tablets to small laptops
  `}

  ${media.between("tabletM", "tabletL")`
    // Styles for medium tablets to large tablets
  `}

  ${media.between("tabletS", "tabletM")`
    // Styles for small tablets to medium tablets
  `}

  ${media.between("mobileL", "tabletS")`
    // Styles for large mobiles to small tablets
  `}

  ${media.between("mobileM", "mobileL")`
    // Styles for medium mobiles to large mobiles
  `}

  ${media.down("mobileM")`
    // Styles for devices smaller than medium mobiles
  `}
`;

export const Heading = styled.h1`
  color: ${({ theme }) => theme.textColorPrimary};
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: start;
  padding: 0 0 0 1rem;
  margin: 0;

  ${media.down("tabletS")`
    padding: 0 0 0 0.75rem;
    justify-self: start;
    font-size: ${typography.fontSizeMedium};
  `}

  ${media.between("tabletS", "tabletM")`
    padding: 0 0 0 0.75rem;
    justify-self: start;
    font-size: ${typography.fontSizeLarge};
  `}

  ${media.up("tabletM")`
    padding: 0 0 0 0.75rem;
    justify-self: start;
    font-size: ${typography.fontSizeLarge};
  `}

  @media (orientation: landscape) {
    ${media.between("tabletS", "tabletL")`
      padding: 0 0 0 0.75rem;
      justify-self: start;
      font-size: ${typography.fontSizeLarge};
    `}
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => hexToRgba(theme.accentColorPrimary, 1)};
  color: ${({ theme }) => theme.textColorPrimary};
  font-weight: bold;
  width: 100%;

  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.primaryColor};
    background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 1)};
    color: ${({ theme }) => theme.textColorPrimary};
    box-shadow: 0 0 0 3px ${({ theme }) => `${theme.primaryColor}33`};
    transform: ${({ $disabled }) => ($disabled ? "none" : "translateY(-5px)")};
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("laptopS", "laptopL")`
  `}

  ${media.between("tabletL", "laptopS")`
  `}

  ${media.between("tabletM", "tabletL")`
  `}

  ${media.between("tabletS", "tabletM")`
  `}

  ${media.between("mobileL", "tabletS")`
  `}

  ${media.between("mobileM", "mobileL")`
  `}

  ${media.down("mobileM")`
  `}
`;

export const Input = styled.input`
  padding: 0.75rem;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  font-size: 1rem;
  background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 0.9)};
  color: ${({ theme }) => theme.textColorPrimary};
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primaryColor};
    box-shadow: 0 0 0 3px ${({ theme }) => `${theme.primaryColor}33`};
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholderColor};
  }

  ${media.up("desktop")`
    width: 100%;
  `}

  ${media.between("laptopL", "desktop")`
    width: 100%;
  `}

  ${media.between("laptopS", "laptopL")`
    // Styles for small laptops to large laptops
  `}

  ${media.between("tabletL", "laptopS")`
    // Styles for large tablets to small laptops
  `}

  ${media.between("tabletM", "tabletL")`
    // Styles for medium tablets to large tablets
  `}

  ${media.between("tabletS", "tabletM")`
    // Styles for small tablets to medium tablets
  `}

  ${media.between("mobileL", "tabletS")`
    // Styles for large mobiles to small tablets
  `}

  ${media.between("mobileM", "mobileL")`
    // Styles for medium mobiles to large mobiles
  `}

  ${media.down("mobileM")`
    // Styles for devices smaller than medium mobiles
  `}
`;

export const Textarea = styled.textarea`
  padding: 0.75rem;
  border-radius: 10px;
  font-size: 1.2rem;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  background-color: ${({ theme }) => hexToRgba(theme.bodyBgPrimary, 0.9)};
  color: ${({ theme }) => theme.textColorPrimary};
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primaryColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholderColor};
  }

  ${media.up("desktop")`
    width: 100%;
  `}

  ${media.between("laptopL", "desktop")`
    width: 100%;
  `}

  ${media.between("laptopS", "laptopL")`
    // Styles for small laptops to large laptops
  `}

  ${media.between("tabletL", "laptopS")`
    // Styles for large tablets to small laptops
  `}

  ${media.between("tabletM", "tabletL")`
    // Styles for medium tablets to large tablets
  `}

  ${media.between("tabletS", "tabletM")`
    // Styles for small tablets to medium tablets
  `}

  ${media.between("mobileL", "tabletS")`
    // Styles for large mobiles to small tablets
  `}

  ${media.between("mobileM", "mobileL")`
    // Styles for medium mobiles to large mobiles
  `}

  ${media.down("mobileM")`
    // Styles for devices smaller than medium mobiles
  `}
`;

export const MessageDiv = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: ${({ theme, isSuccess, isError }) =>
    isSuccess
      ? theme.successBackground
      : isError
        ? theme.errorBackground
        : "transparent"};
  color: ${({ theme, isSuccess, isError }) =>
    isSuccess
      ? theme.successColor
      : isError
        ? theme.errorColor
        : theme.textColor};
  text-align: center;
`;
