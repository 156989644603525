// styles/GlobalStyle.js

import { createGlobalStyle } from "styled-components";
import { typography } from "./abstracts/typography";

const GlobalStyle = createGlobalStyle`

  #root,
  html {
    width: 100%;
    height: 100vh;
  }
  
  html, body {
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
  }
  
  body {
    font-family: ${typography.fontBody};
    font-size: ${typography.fontSizeSmall};
    width: 100%;
    height: 100vh;
    touch-action: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${typography.fontHeadline};
  }

  h1 {
    font-size: ${typography.fontSizeLarge};
  }

  h2 {
    font-size: ${typography.fontSizeMedium};
  }

  p {
    font-size: ${typography.fontSizeSmall};
    line-height: ${typography.fontHeightMd};
    margin: 0;
  }
`;

export default GlobalStyle;
