import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useViewport } from "../../context/ViewportContext";
import useStore from "../../stores/store";
import {
  Wrapper,
  Content,
  ContentWrapper,
  ScrollContent,
} from "../../components/BentoBox/StyledComponents";

import {
  BentoBox,
  BentoItem,
  BentoWrapper,
} from "../../components/BentoBox/BentoBox";
import { breakpoints, media } from "../../styles/breakpoints";
import Scrollbar from "../../components/Scrollbar/Scrollbar";
import styled from "styled-components";
import ContactForm from "../ContactForm/ContactForm";
import { Heading } from "../ContactForm/ContactForm.styles";
import FormContainer from "./FormContainer/FormContainer";
import { typography } from "../../styles/abstracts/typography";
import OpenTo from "./OpenTo/OpenTo";
import ContactInterests from "./ContactInterests/ContactInterests";
import Expectations from "./Expectations/Expectations";
import {
  ContactBento,
  ContactContainer,
  ContactFormWrapper,
} from "./ContactBento/ContactBento.styles";

const StyledContent = styled(Content)`
  grid-column: 2 /-1;
  grid-row: 2 /-1;
`;

const StyledWrapper = styled(Wrapper)`
  grid-template-columns: 5.25rem 1fr;
  grid-template-rows: 5.25rem 1fr 5.25rem;

  ${media.up("desktop")`
    width: 100%;
  `}

  ${media.between("laptopL", "desktop")`
    width: 100%;
  `}

  ${media.between("laptopS", "laptopL")`
    // Styles for small laptops to large laptops
  `}

  ${media.between("tabletL", "laptopS")`
    // Styles for large tablets to small laptops
  `}

  ${media.between("tabletM", "tabletL")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr 5.25rem;
  `}

  ${media.between("tabletS", "tabletM")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr 5.25rem;
  `}

  ${media.between("mobileL", "tabletS")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3.5rem 1fr 5.25rem;
  `}

  ${media.between("mobileM", "mobileL")`
    grid-template-columns: 3rem 1fr;
    grid-template-rows: 3rem 1fr 5.25rem;
  `}

  ${media.down("mobileM")`
    grid-template-columns: 3rem 1fr;
    grid-template-rows: 3rem 1fr 5.25rem;
  `}
`;

const StyledContentWrapper = styled(ContentWrapper)`
  height: 100%;

  ${media.up("desktop")`

  `}

  ${media.between("laptopS", "desktop")`
padding: 0;
  `}

  ${media.between("tabletL", "laptopS")`

  `}

  ${media.between("tabletS", "tabletL")`

  `}

  ${media.between("mobileL", "tabletS")`
  height: auto;

  `}

  ${media.down("mobileL")`
  height: auto;

  `}
`;

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);
  const viewport = useViewport();
  const { width, height } = viewport;
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();
  const [h2Content, setH2Content] = useState("Let's Create Something.");

  const updateHeading = (newHeading) => {
    setH2Content(newHeading);
  };

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowScrollbar(contentHeight > containerHeight);
    }
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  return (
    <StyledWrapper theme={theme}>
      <Heading theme={theme} $viewport={viewport}>
        [ Get in Touch ]
      </Heading>
      <StyledContent>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          <ScrollContent>
            <StyledContentWrapper>
              <ContactBento>
                <ContactFormWrapper disableBackground={true}>
                  <FormContainer headline={h2Content}>
                    <p>Ready to collaborate?</p>
                    <p>
                      Have a wild idea you want to bounce off someone? Or maybe
                      you just want to say hi?{" "}
                    </p>
                    <p>Whatever it is, I'm all ears.</p>
                    <ContactForm
                      updateHeading={updateHeading}
                      $viewport={viewport}
                    />
                  </FormContainer>
                </ContactFormWrapper>
                <OpenTo
                  items={[
                    "Coffee Chats",
                    "Collaborations",
                    "Project Sprints",
                    "Team Openings",
                    "Speaking Engagements",
                  ]}
                />
                <ContactInterests />
                <Expectations />
              </ContactBento>
            </StyledContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </StyledContent>
    </StyledWrapper>
  );
};

export default Contact;
