// components/Menu/Nav/Nav.styled.jsx

import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { breakpoints } from "../../../styles/breakpoints";
import { typography } from "../../../styles/abstracts/typography";

export const NavWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        padding: ${isLandscape ? "30px 15px 15px 15px" : "1.5rem"};
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: ${isLandscape ? "2rem 1rem 1.5rem 1.5rem" : "2rem 1.5rem"};
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        padding: ${isLandscape ? "2rem 1rem 1.5rem 1.5rem" : "2rem 1.5rem"};
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        flex-direction: row;
        padding: ${isLandscape ? "2rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        flex-direction: row;
        padding: ${isLandscape ? "2rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        flex-direction: row;
        padding: ${isLandscape ? "2rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        padding: ${isLandscape ? "2rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        padding: ${isLandscape ? "2.5rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};

      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        padding: ${isLandscape ? "2.5rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};
      `;
    }
    return `
      padding: ${isLandscape ? "2.5rem 1rem 1.5rem 2rem" : "2rem 1.5rem"};
    `;
  }}
`;

export const LinkContainer = styled.div`
  perspective: 120px;
  perspective-origin: bottom;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.textColorLight || "#FFF"};
  text-decoration: none;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `font-size: ${isLandscape ? "1.5rem" : "1.75rem"};`;
    }
    if (width <= breakpoints.mobileM) {
      return `font-size: ${isLandscape ? "1.6rem" : "2.25rem"};`;
    }
    if (width <= breakpoints.mobileL) {
      return `font-size: ${isLandscape ? "1.75rem" : "2.25rem"};`;
    }
    if (width <= breakpoints.tabletS) {
      return `
        font-size: ${isLandscape ? "2.25rem" : "2.75rem"};
        line-height: ${isLandscape ? "2.5rem" : "3.25rem"};
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? "2.25rem" : "2.25rem"};
        line-height: ${isLandscape ? "2.5rem" : "2.5rem"};
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        font-size: ${isLandscape ? "2.25rem" : "2.75rem"};
        line-height: ${isLandscape ? "2.5rem" : "3.25rem"};
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `font-size: 3rem;`;
    }
    if (width <= breakpoints.laptopM) {
      return `font-size: 3.1rem;`;
    }
    if (width <= breakpoints.laptopL) {
      return `font-size: 3.2rem;`;
    }
    return `font-size: 3.25rem;`;
  }}
`;

export const NavBody = styled.div`
  display: flex;
  flex-direction: column;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `gap: ${isLandscape ? "5px" : "10px"};`;
    }
    if (width <= breakpoints.mobileM) {
      return `gap: ${isLandscape ? "6px" : "10px"};`;
    }
    if (width <= breakpoints.mobileL) {
      return `gap: ${isLandscape ? "7px" : "10px"};`;
    }
    if (width <= breakpoints.tabletS) {
      return `gap: ${isLandscape ? "8px" : "10px"};`;
    }
    if (width <= breakpoints.tabletM) {
      return `gap: ${isLandscape ? "9px" : "10px"};`;
    }
    if (width <= breakpoints.tabletL) {
      return `gap: ${isLandscape ? "5px" : "10px"};`;
    }
    return `gap: 10px;`;
  }}
`;

export const NavFooter = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        width: 45%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "3.5rem"};
        position: absolute;
        bottom: 1.5rem;
        right: 1.25rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        width: 45%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "3.5rem"};
        position: absolute;
        bottom: 1.25rem;
        right: 1.25rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        width: 40%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "4rem"};
        position: absolute;
        bottom: 0.75rem;
        right: 1.5rem;

        /* iPhone 11, 11 Pro Max, XR, XS Max specific adjustment */
        @media only screen 
          and (device-width: 414px) 
          and (device-height: 896px) 
          and (-webkit-device-pixel-ratio: 2),
          only screen 
          and (device-width: 414px) 
          and (device-height: 896px) 
          and (-webkit-device-pixel-ratio: 3) {
            bottom: 5.5rem;
            right: 1rem;
            width: 45%;
        }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        width: 40%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "4rem"};
        position: fixed;
        bottom: 1rem;
        right: 1.5rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        width: ${isLandscape ? "40%" : "40%"};
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "3rem"};
        position: absolute;
        bottom: 0.75rem;
        right: ${isLandscape ? "1.5rem" : "1.5rem"};
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        width: 40%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "4rem"};
        position: absolute;
        bottom: 0.75rem;
        right: 1.5rem;
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        width: 40%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "3rem" : "4rem"};
        position: absolute;
        bottom: 0.75rem;
        right: 1.5rem;
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        width: 30%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "4rem" : "4rem"};
        position: absolute;
        bottom: 0.75rem;
        right: 1.75rem;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        width: 30%;
        margin-left: auto;
        align-self: flex-end;
        height: ${isLandscape ? "4rem" : "4rem"};
        position: absolute;
        bottom: 0.75rem;
        right: 1.75rem;
      `;
    }
    return `

    `;
  }}
`;

export const FooterLink = styled(motion.a)`
  color: ${({ theme }) => theme.textColorLight || "#FFF"};
  text-decoration: none;
  margin-top: 5px;
  width: 50%;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: 0.75rem;
        text-align: right;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        font-size: 0.75rem;
        text-align: right;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        font-size: 0.9rem;
        text-align: right;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        font-size: 0.9rem;
        text-align: right;
        width: 50%;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? "0.9rem" : "0.75rem"} ;
        text-align: right;
        width: 50%;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        font-size: 0.9rem;
        text-align: right;
        width: 50%;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        font-size: 0.9rem;
        text-align: right;
        width: 50%;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        font-size: 0.9rem;
        text-align: right;
        width: 50%;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        font-size: 0.9rem;
        text-align: right;
        width: 50%;
        margin-top: ${isLandscape ? "3px" : "5px"};
      `;
    }
    return `
      font-size: 1rem;
    `;
  }}
`;
