import React from "react";
import {
  SiteProjectContent,
  SiteProjectContentWrapper,
} from "./SiteProject.styles";

function SiteProject() {
  return (
    <SiteProjectContentWrapper disableBackground={true}>
      <h2>The Site Project:</h2>
      <SiteProjectContent>
        <p>
          Dormant for eight years, now revived as my digital workshop – this
          site is where I archive projects, host my lab environment, and hone my
          development skills.
        </p>
        <p>
          More than just a digital presence, this is how I "walk the walk" of
          continuous learning, technological adaptation, and the exploration of
          what's possible in marketing, web development, and experiential
          storytelling.
        </p>
      </SiteProjectContent>
    </SiteProjectContentWrapper>
  );
}

export default SiteProject;
